export default {
  headers: [
    {
      id: 'mobileHeader',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/jakljan/logo.png',
        url: 'https://www.zteamaz.com/',
      },
      breakpoint: 1000,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: true,
          reverse: false,
        },
        headerClassName: 'on-jakljan-mobile-header-top',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: 'on-jakljan-mobile-top-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'login-item',
            content: 'Login / Register',
            url: 'https://www.zteamaz.com/property-search/property-tracker/',
            className: 'on-jakljan-special-item',
          },
          {
            id: 'blog-item',
            content: 'Blog',
            url: 'https://www.zteamaz.com/blog/',
            className: 'on-jakljan-special-item',
          },
          {
            id: 'header-jakljan-phone-item',
            iconSet: 'fa',
            className: 'phone',
            content: '(480) 332-6468',
            url: 'tel:+14803326468',
          },
        ],
        items: [],
      },
    },
    {
      id: 'hiddenHeader',
      logo: {
        alt: 'logo',
        src: '',
        url: '',
      },
      breakpoint: -1,

      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: 'on-jakljan-hidden-header',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: true,
          reverse: false,
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [],
      },
    },
    {
      id: 'mainHeader',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/jakljan/logo.png',
        url: 'https://www.zteamaz.com/',
      },
      breakpoint: 992,
      desktopMenu: {
        type: 'center', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-jakljan-desktop-logo-c',
          imageClassName: 'on-jakljan-desktop-logo',
        },
        headerClassName: 'on-jakljan-desktop-header',
        headerSectionsClassName: 'on-jakljan-desktop-header-sections',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-jakljan-desktop-item',
        menuClassName: 'on-jakljan-desktop-menu',
        menuLabelActiveClassName: 'on-jakljan-desktop-label',
        menuItemClassName: 'on-jakljan-desktop-menu-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: 'on-jakljan-desktop-sub-label',
        subMenuItemClassName: 'on-jakljan-desktop-sub-menu-item',
        items: [
          {
            id: 'search-item',
            content: 'Search',
            url: 'https://www.zteamaz.com/property-search/search-form/',
            menuItems: [
              {
                id: 'search-item-advanced',
                content: 'Advanced Search',
                url: 'https://www.zteamaz.com/property-search/search-form/',
              },
              {
                id: 'search-item-map',
                content: 'Search by Map',
                url: 'https://www.zteamaz.com/property-search/results/?searchtype=3',
              },
              {
                id: 'search-item-property-tracker',
                content: 'Property Tracker',
                url: 'https://www.zteamaz.com/property-search/property-tracker/',
              },
              {
                id: 'search-item-featured-listings',
                content: 'Featured Listings',
                url: 'https://www.zteamaz.com/featured-listings/',
              },
              {
                id: 'search-item-all-cash',
                content: 'Become an ALL-CASH BUYER',
                url: 'https://cashoffer.zteamaz.com',
              },
              {
                id: 'search-item-trade-in',
                content: 'Buy Before You Sell - TRADE-IN',
                url: 'https://trade-in.zteamaz.com',
              },
            ],
          },
          {
            id: 'communities-item',
            content: 'Communities',
            url: 'https://www.zteamaz.com/communities/',
          },
          {
            id: 'menu-item-buyers',
            content: 'Buyers',
            url: 'https://www.zteamaz.com/buyers/',
            menuItems: [
              {
                id: 'menu-item-buyers-buyers',
                content: 'Buyers',
                url: 'https://www.zteamaz.com/buyers/',
              },
              {
                id: 'menu-item-buyers-calculator',
                content: 'Mortgage Calculator',
                url: 'https://www.zteamaz.com/buyers/mortgage-calculator/',
              },
              {
                id: 'menu-item-buyers-mortgage',
                content: 'Mortgage Pre-Approval',
                url: 'https://www.zteamaz.com/buyers/mortgage-pre-approval/',
              },
              {
                id: 'menu-item-buyers-first-time',
                content: 'First Time Buyers',
                url: 'https://www.zteamaz.com/buyers/first-time-buyers/',
              },
              {
                id: 'menu-item-buyers-making-offer',
                content: 'Making an Offer',
                url: 'https://www.zteamaz.com/buyers/making-an-offer/',
              },
              {
                id: 'menu-item-buyers-closing-costs',
                content: 'What Are Closing Costs?',
                url: 'https://www.zteamaz.com/buyers/what-are-closing-costs/',
              },
              {
                id: 'menu-item-buyers-escrow',
                content: 'Escrow: Now What?',
                url: 'https://www.zteamaz.com/buyers/escrow-now-what/',
              },
              {
                id: 'menu-item-buyers-financial-terms',
                content: 'Financial Terms Glossary',
                url: 'https://www.zteamaz.com/buyers/financial-terms-glossary/',
              },
              {
                id: 'menu-item-buyers-personalized',
                content: 'Personalized Home Search',
                url: 'https://www.zteamaz.com/buyers/personalized-home-search/',
              },
              {
                id: 'menu-item-buyers-become-cash-buyer',
                content: 'Become a Cash Buyer today!',
                url: 'https://www.zteamaz.com/buyers/win-with-a-cash-offer/',
              },
            ],
          },
          {
            id: 'menu-item-sellers',
            content: 'Sellers',
            url: 'https://www.zteamaz.com/sellers/',
            menuItems: [
              {
                id: 'menu-item-sellers-selling-home',
                content: 'Selling a Home',
                url: 'https://www.zteamaz.com/sellers/',
              },
              {
                id: 'menu-item-sellers-pricing-home',
                content: 'Pricing Your Home',
                url: 'https://www.zteamaz.com/sellers/pricing-your-home/',
              },
              {
                id: 'menu-item-sellers-marketing-home',
                content: 'Marketing Your Home',
                url: 'https://www.zteamaz.com/sellers/marketing-your-home/',
              },
              {
                id: 'menu-item-sellers-showing-home',
                content: 'Showing Your Home',
                url: 'https://www.zteamaz.com/sellers/showing-your-home/',
              },
              {
                id: 'menu-item-sellers-adding-value',
                content: 'Adding Value',
                url: 'https://www.zteamaz.com/sellers/adding-value/',
              },
              {
                id: 'menu-item-sellers-free-market-analysis',
                content: 'Free Market Analysis',
                url: 'https://www.zteamaz.com/sellers/free-market-analysis/',
              },
            ],
          },
          {
            id: 'menu-item-about',
            content: 'About',
            url: 'https://www.zteamaz.com/about/',
            menuItems: [
              {
                id: 'menu-item-about-about',
                content: 'About',
                url: 'https://www.zteamaz.com/about/',
              },
              {
                id: 'menu-item-about-applying',
                content: 'Applying to work with the Z Team',
                url: 'https://www.zteamaz.com/about/apply-to-work-with-the-z-team/',
              },
            ],
          },
          {
            id: 'menu-item-contact',
            content: 'Contact',
            url: 'https://www.zteamaz.com/contact/',
          },
          {
            id: 'login-item',
            content: 'Login / Register',
            url: 'https://www.zteamaz.com/property-search/property-tracker/',
            className: 'on-jakljan-special-item',
          },
          {
            id: 'blog-item',
            content: 'Blog',
            url: 'https://www.zteamaz.com/blog/',
            className: 'on-jakljan-special-item',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-jakljan-mobile-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-jakljan-mobile-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-jakljan-mobile-menu',
        itemClassName: 'on-jakljan-mobile-item',
        menuClassName: 'on-jakljan-mobile-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-jakljan-mobile-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'home-item-mobile',
            content: 'Home',
            url: 'https://www.zteamaz.com/',
          },
          {
            id: 'blog-item-mobile',
            content: 'Blog',
            url: 'https://www.zteamaz.com/blog/',
          },
          {
            id: 'search-item-mobile',
            content: 'Search',
            menuItems: [
              {
                id: 'search-item-mobile-advanced',
                content: 'Advanced Search',
                url: 'https://www.zteamaz.com/property-search/search-form/',
              },
              {
                id: 'search-item-mobile-map',
                content: 'Search by Map',
                url: 'https://www.zteamaz.com/property-search/results/?searchtype=3',
              },
              {
                id: 'search-item-mobile-property-tracker',
                content: 'Property Tracker',
                url: 'https://www.zteamaz.com/property-search/property-tracker/',
              },
              {
                id: 'search-item-mobile-featured-listings',
                content: 'Featured Listings',
                url: 'https://www.zteamaz.com/featured-listings/',
              },
              {
                id: 'search-item-mobile-all-cash',
                content: 'Become an ALL-CASH BUYER',
                url: 'https://cashoffer.zteamaz.com',
              },
              {
                id: 'search-item-mobile-trade-in',
                content: 'Buy Before You Sell - TRADE-IN',
                url: 'https://trade-in.zteamaz.com',
              },
            ],
          },
          {
            id: 'communities-item-mobile',
            content: 'Communities',
            url: 'https://www.zteamaz.com/communities/',
          },
          {
            id: 'menu-item-mobile-buyers',
            content: 'Buyers',
            menuItems: [
              {
                id: 'menu-item-mobile-buyers-buyers',
                content: 'Buyers',
                url: 'https://www.zteamaz.com/buyers/',
              },
              {
                id: 'menu-item-mobile-buyers-calculator',
                content: 'Mortgage Calculator',
                url: 'https://www.zteamaz.com/buyers/mortgage-calculator/',
              },
              {
                id: 'menu-item-mobile-buyers-mortgage',
                content: 'Mortgage Pre-Approval',
                url: 'https://www.zteamaz.com/buyers/mortgage-pre-approval/',
              },
              {
                id: 'menu-item-mobile-buyers-first-time',
                content: 'First Time Buyers',
                url: 'https://www.zteamaz.com/buyers/first-time-buyers/',
              },
              {
                id: 'menu-item-mobile-buyers-making-offer',
                content: 'Making an Offer',
                url: 'https://www.zteamaz.com/buyers/making-an-offer/',
              },
              {
                id: 'menu-item-mobile-buyers-closing-costs',
                content: 'What Are Closing Costs?',
                url: 'https://www.zteamaz.com/buyers/what-are-closing-costs/',
              },
              {
                id: 'menu-item-mobile-buyers-escrow',
                content: 'Escrow: Now What?',
                url: 'https://www.zteamaz.com/buyers/escrow-now-what/',
              },
              {
                id: 'menu-item-mobile-buyers-financial-terms',
                content: 'Financial Terms Glossary',
                url: 'https://www.zteamaz.com/buyers/financial-terms-glossary/',
              },
              {
                id: 'menu-item-mobile-buyers-personalized',
                content: 'Personalized Home Search',
                url: 'https://www.zteamaz.com/buyers/personalized-home-search/',
              },
              {
                id: 'menu-item-mobile-buyers-become-cash-buyer',
                content: 'Become a Cash Buyer today!',
                url: 'https://www.zteamaz.com/buyers/win-with-a-cash-offer/',
              },
            ],
          },
          {
            id: 'menu-item-mobile-sellers',
            content: 'Sellers',
            menuItems: [
              {
                id: 'menu-item-mobile-sellers-selling-home',
                content: 'Selling a Home',
                url: 'https://www.zteamaz.com/sellers/',
              },
              {
                id: 'menu-item-mobile-sellers-pricing-home',
                content: 'Pricing Your Home',
                url: 'https://www.zteamaz.com/sellers/pricing-your-home/',
              },
              {
                id: 'menu-item-mobile-sellers-marketing-home',
                content: 'Marketing Your Home',
                url: 'https://www.zteamaz.com/sellers/marketing-your-home/',
              },
              {
                id: 'menu-item-mobile-sellers-showing-home',
                content: 'Showing Your Home',
                url: 'https://www.zteamaz.com/sellers/showing-your-home/',
              },
              {
                id: 'menu-item-mobile-sellers-adding-value',
                content: 'Adding Value',
                url: 'https://www.zteamaz.com/sellers/adding-value/',
              },
              {
                id: 'menu-item-mobile-sellers-free-market-analysis',
                content: 'Free Market Analysis',
                url: 'https://www.zteamaz.com/sellers/free-market-analysis/',
              },
            ],
          },
          {
            id: 'menu-item-mobile-about',
            content: 'About',
            menuItems: [
              {
                id: 'menu-item-mobile-about-about',
                content: 'About',
                url: 'https://www.zteamaz.com/about/',
              },
              {
                id: 'menu-item-mobile-about-applying',
                content: 'Applying to work with the Z Team',
                url: 'https://www.zteamaz.com/about/apply-to-work-with-the-z-team/',
              },
            ],
          },
          {
            id: 'menu-item-mobile-contact',
            content: 'Contact',
            url: 'https://www.zteamaz.com/contact/',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-jakljan-footer-first',
      display: true,
      className: 'on-jakljan-footer-section-one',
      sectionClassName: 'on-jakljan-footer-section-container',
      columns: [
        {
          id: 'f-1-col-1',
          className: 'align-center on-jakljan-footer-img-container',
          style: {},
          items: [
            {
              id: 'f-1-col-1-img',
              type: 'image',
              className: 'on-jakljan-footer-image',
              style: {},
              linkUrl: 'https://www.zteamaz.com/',
              url: 'https://si-homelight.s3.amazonaws.com/sites/jakljan/logo-footer.png',
            },
          ],
        },
      ],
    },

    {
      id: 'on-jakljan-footer-second',
      display: true,
      className: 'on-jakljan-footer-section-two',
      sectionClassName: 'on-jakljan-footer-section-container on-jakljan-footer-section-container-two',
      columns: [
        {
          id: 'f-2-col-1',
          className: 'on-jakljan-footer-column col-md-3 col-sm-12',
          style: {},
          items: [
            {
              id: 'f-2-col-1-h1',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'CONTACT',
            },
            {
              id: 'f-2-col-1-item-1',
              type: 'heading',
              elementType: 'p',
              className: '',
              style: {},
              data: 'The Z TEAM',
            },
            {
              id: 'f-2-col-1-item-1-1',
              type: 'heading',
              elementType: 'p',
              className: '',
              style: {},
              data: 'eXp Realty',
            },
            {
              id: 'f-2-col-1-item-2',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: '6991 E. Camelback Rd. D-300',
            },
            {
              id: 'f-2-col-1-item-3',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              style: {},
              data: 'Scottsdale, AZ 85251',
            },
            {
              id: 'f-2-col-1-item-6',
              type: 'itemContent',
              className: 'mb-0',
              style: {},
              items: [
                {
                  content: 'O: ',
                },
                {
                  content: '(480) 442-7577',
                  url: 'tel:+14804427577',
                  className: 'on-jakljan-phone-link',
                },
              ],
            },
            {
              id: 'f-2-col-1-item-4',
              type: 'itemContent',
              className: 'mb-0',
              style: {},
              items: [
                {
                  content: 'M: ',
                },
                {
                  content: '(480) 332-6468',
                  url: 'tel:+14803326468',
                  className: 'on-jakljan-phone-link',
                },
              ],
            },
            {
              id: 'f-2-col-1-item-5',
              type: 'itemContent',
              className: 'mb-0',
              style: {},
              items: [
                {
                  content: 'E: ',
                },
                {
                  content: 'Email Us',
                  url: 'mailto:admin@zteamaz.com',
                  className: 'on-jakljan-email-link',
                },
              ],
            },
          ],
        },
        {
          id: 'f-col-2',
          className: 'on-jakljan-footer-column col-md-3 col-sm-12',
          style: {},
          items: [
            {
              id: 'col-two-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'CONNECT',
            },
            {
              id: 'col-two-socials',
              type: 'socialIcons',
              className: 'on-jakljan-footer-socials',
              style: {},
              items: [
                {
                  id: 'on-jakljan-fcb',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  data: 'Facebook',
                  className: 'fbb',
                  url: 'https://www.facebook.com/ZTEAMAZ/',
                },
                {
                  id: 'on-jakljan-yt',
                  iconTypeClassName: 'fab',
                  iconClassName: 'youtube',
                  data: 'Youtube',
                  url: 'https://www.youtube.com/channel/UCzr8wWq8UMPxLOBygZ06dgw?view_as=subscriber',
                },
              ],
            },
          ],
        },
        {
          id: 'col-three',
          className: 'on-jakljan-footer-column  col-md-3 col-sm-12',
          style: {},
          items: [
            {
              id: 'col-three-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'NAVIGATION',
            },
            {
              id: 'col-three-menu',
              type: 'menu',
              className: 'on-jakljan-footer-menu',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-three-item-2',
                  data: 'Search',
                  url: 'https://www.zteamaz.com/property-search/search-form/',
                },
                {
                  id: 'col-three-item-4',
                  data: 'Communities',
                  url: 'https://www.zteamaz.com/communities/',
                },
                {
                  id: 'col-three-item-1',
                  data: 'Buyers',
                  url: 'https://www.zteamaz.com/buyers/',
                },
                {
                  id: 'col-three-item-3',
                  data: 'Sellers',
                  url: 'https://www.zteamaz.com/sellers/',
                },
                {
                  id: 'col-three-item-7',
                  data: 'About',
                  url: 'https://www.zteamaz.com/about/',
                },
                {
                  id: 'col-three-item-6',
                  data: 'Contact',
                  url: 'https://www.zteamaz.com/contact/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-four',
          className: 'on-jakljan-footer-column col-md-3 col-sm-12',
          style: {},
          items: [
            {
              id: 'col-four-h1',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'POPULAR SEARCHES',
            },
            {
              id: 'col-four-menu',
              type: 'menu',
              className: 'on-jakljan-footer-menu',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-four-item-1',
                  data: 'Featured Listings',
                  url: 'https://www.zteamaz.com/property-search/results/?searchid=470443',
                },
                {
                  id: 'col-four-item-2',
                  data: 'Newest Listings',
                  url: 'https://www.zteamaz.com/property-search/results/?searchid=470445',
                },
                {
                  id: 'col-four-item-4',
                  data: 'Single Family',
                  url: 'https://www.zteamaz.com/property-search/results/?searchid=470446',
                },
                {
                  id: 'col-four-item-3',
                  data: 'Condos & Townhomes',
                  url: 'https://www.zteamaz.com/property-search/results/?searchid=470447',
                },
                {
                  id: 'col-four-item-5',
                  data: 'Luxury Homes',
                  url: 'https://www.zteamaz.com/property-search/results/?searchid=470449',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-jakljan-footer-third',
      display: true,
      className: 'on-jakljan-footer-section-three',
      sectionClassName: 'on-jakljan-footer-section-container',
      columns: [
        {
          id: 'f-3-col-1',
          className: 'col-lg-6',
          style: {},
          items: [
            {
              id: 'f-3-col-1-item-1',
              type: 'menu',
              className: 'on-jakljan-footer-privacy-menu',
              style: {},
              items: [
                {
                  id: 'f-3-col-1-menu-1',
                  data: 'Accessibility',
                  className: 'on-jakljan-footer-privacy-menu-item-first',
                  url: 'https://www.zteamaz.com/accessibility/',
                },
                {
                  id: 'f-3-l-1',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-2',
                  data: 'Terms of Service',
                  url: 'https://www.zteamaz.com/terms-of-service/',
                },
                {
                  id: 'f-3-l-2',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-3',
                  data: 'Privacy Policy',
                  url: 'https://www.zteamaz.com/privacy-policy/',
                },
                {
                  id: 'f-3-l-3',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-4',
                  data: 'DMCA Notice',
                  url: 'https://www.zteamaz.com/dmca-notice/',
                },
                {
                  id: 'f-3-l-4',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-5',
                  data: 'Property Listings',
                  url: 'https://www.zteamaz.com/property-search/site-map/',
                },
                {
                  id: 'f-3-l-5',
                  data: '|',
                },
                {
                  id: 'f-3-col-1-menu-6',
                  data: 'Sitemap',
                  url: 'https://www.zteamaz.com/site-map/',
                },
              ],
            },
            {
              id: 'f-3-col-1-item-2',
              type: 'heading',
              className: '',
              style: {},
              data: '© Copyright 2022 The Dave Z Home Team. All Rights Reserved.',
            },
          ],
        },
        {
          id: 'f-3-col-2',
          className: 'col-lg-2 on-footer-jakljan-img-col',
          style: {},
          items: [
            {
              id: 'f-3-col-2-item-1',
              type: 'image',
              className: 'on-jakljan-footer-realtor-logo',
              url: 'https://si-homelight.s3.amazonaws.com/sites/jakljan/logo-footer-equal-realtor.png',
            },
          ],
        },
        {
          id: 'f-3-col-3',
          className: 'col-lg-4 on-jakljan-f-3-menu-col',
          style: {},
          items: [
            {
              id: 'f-3-col-3-menu',
              type: 'menu',
              className: 'on-jakljan-footer-menu-sierra col-lg-4',
              itemClassName: 'mb-0',
              style: {},
              url: 'https://www.sierrainteractive.com/',
              items: [
                {
                  id: 'col-3-item-1-real-estate',
                  data: 'Real Estate Websites by ',
                  url: 'https://www.sierrainteractive.com/',
                },
                {
                  id: 'col-3-item-2-sierra',
                  data: 'Sierra Interactive',
                  url: 'https://www.sierrainteractive.com/',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {
    'tradeIn.hero.className': 'heroClassName',
    'cashOffer.hero.className': 'heroClassName',
  },
  siteData: {
    agency: 'The Dave Z Home Team brokered by eXp Realty',
    agentDescription: 'Top agents in Chandler, AZ',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/jakljan/agent.png',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/jakljan/hero-bg.jpg',
    heroClassName: 'on-jakljan-hero',
  },
};
